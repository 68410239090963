/**Form fields */
body {
    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder,
    :-moz-placeholder {
        font-family: $font-muli-regular;
        color: $txt-line-break;
    }
    input {
        background-color: $white;
        background-image: none;
        border: 1px solid $login-linebreak-grey;
        @include borderRadius(4px);
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        height: 32px;
        padding: 4px 11px;
        color: $txt-line-break;
        font-size: 14px;
        line-height: 32px;
    }
    form {
        label {
            @include font($font-small-size, 'Muli Regular');
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $black;
            text-transform: capitalize;
        }
        input.ant-input {
            @include buttonSpec($pale-white, $white, inherit);
            @include borderRadius(10px);
            padding: 20px 12px;
            padding-right: 28px !important;
        }
        .add-field {
            @include size(202px, 40px);
            margin-right: 15px;
            margin-bottom: 8px;
        }
        textarea.ant-input {
            min-height: 100px;
            border-radius: 8px;
        }
    }
    .ant-layout .ant-select-auto-complete.ant-select {
        .ant-select-selection--single {
            padding: 0;
            height: 38px;
            border-radius: 10px;
        }
        .ant-select-search--inline {
            padding: 0;
            input {
                border-radius: 10px;
                width: 100% !important;
                height: 40px;
            }
        }
    }
}

.form-wrapper {
    float: left;
    background: $white;
    @include borderRadius(10px);
    box-shadow: 0 8px 6px 0 rgba(215, 241, 210, 0.38), 0 0 6px 0 rgba(0, 0, 0, 0.12);
}

.form-title {
    @include font($font-mediumToLg-size, $font-muli-semibold);
    color: $black;
    margin-bottom: 31px;
    margin-top: 27px;
}

.default-form-app-error .ant-form-explain {
    padding-bottom: 15px;
}

.has-error .ant-form-explain {
    word-wrap: break-word;
}
