@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/variables';

#root .invoice-details-wrapper {
    margin-top: 90px;
}

body {
    .contact-persons-wrapper {
        h2 {
            font-size: 20px;
            text-align: left;

            font-weight: bold;
        }
        .contact-persons-block-title {
            font-size: 20px;
            width: 100%;
            text-align: left;
            margin-top: 10px;
            border-bottom: 2px solid #fafafa;
            margin-bottom: 25px;
            padding-bottom: 10px;
        }

        .contact-person-info-message .info-message-wrapper {
            font-size: 12px;
            width: 100%;
            text-align: left;
            margin-top: 10px;
            border-bottom: 2px solid #fafafa;
            margin-bottom: 25px;
            padding-bottom: 10px;
        }

        .small-add-button {
            padding: 10px !important;
            font-size: 12px;
        }

        .remove-contact-person {
            margin-top: 15px;
            font-size: 20px;
            font-weight: bold;
        }
    }
    .contact-details {
        .avatar-name-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .contact-avatar {
                margin-right: 5px;
            }
        }

        .contact-edit-button {
            float: right;
        }

        .reminders-wrapper {
            .ant-tabs-bar {
                padding: 0 10px 0 10px !important;
            }
            .ant-table-wrapper {
                padding: 0 15px !important;
            }
        }

        .account-name-img-wrapper {
            width: 50px;
            float: left;
            padding: 7px;
            border: 2px solid $border-grey;
            border-radius: 100%;
            margin-right: 11px;
            @media screen and (max-width: $breakpoint-smphone) {
                @include size(50px, 50px);
                img {
                    @include size(100% !important, auto !important);
                }
            }
        }

        .contact-details-spec {
            width: 100%;
        }

        .account-name {
            float: left;
            text-align: left;
            @media screen and (min-width: $breakpoint-tablet) {
                width: 30% !important;
            }
            p {
                margin-top: 12px;
            }
        }
        header .right-align {
            margin-top: 24px;
            @media screen and (max-width: $breakpoint-lgphone) {
                width: 100%;
                float: left;
                text-align: left;
            }
        }

        .invoices-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            > :first-child {
                margin-right: 11px;
            }
            h2.page-title {
                display: none;
            }
            .ant-table-wrapper {
                padding: 0 !important;
            }
        }

        .reminders-header {
            width: 100%;
            overflow: initial;
        }
    }
    .assigned-contacts-wrapper {
        .ant-select-selection__rendered {
            ul {
                li {
                    display: none;
                }
                :last-child {
                    display: inline-block;
                }
            }
        }
        .ant-tag {
            padding: 7px;
            margin: 5px;
        }
    }

    #root .no-padding {
        padding: 0;
    }

    .single-col-detail {
        grid-template-columns: minmax(1fr, 1fr);
    }
}
