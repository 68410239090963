.ant-anchor-link {
    cursor: pointer;
}
.ant-dropdown-menu-item-selected {
    background-color: $bg-txt-highlight;
}
.ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
    overflow: hidden;
}
.ant-radio-wrapper.ant-radio-wrapper-checked {
    border: 2px dashed $pale-green;
    background-color: rgba(215, 241, 210, 0.29);
}

.ant-table-filter-dropdown {
    .ant-radio-wrapper.ant-radio-wrapper-checked {
        border: initial;
    }
    .ant-dropdown-menu-item {
        overflow: initial;
    }
}
.ant-fullcalendar {
    td {
        border: 1px solid $pale-white;
    }
    .ant-fullcalendar-content {
        overflow-y: inherit;
        ul.events {
            li {
                padding: 0;
                .ant-badge-status-text {
                    color: $txt-table-header;
                    font-family: $font-muli-bold;
                }
            }
        }
        .notes-month {
            background-color: $bg-lighter-highlight;
            color: $txt-table-header;
            font-family: $font-muli-bold;
        }
    }
    .ant-fullcalendar-date,
    .ant-fullcalendar-month {
        border-top: transparent;
        margin: 0;
    }
    .ant-fullcalendar-month:hover,
    .ant-fullcalendar-date:hover {
        background: transparent;
    }
    .ant-fullcalendar-selected-day {
        .ant-fullcalendar-date {
            background: $bg-txt-highlight;
            margin: 0;
        }
        .ant-fullcalendar-value {
            color: $txt-tab-link;
            font-family: $font-muli-bold;
        }
    }
    .ant-fullcalendar-month-panel-selected-cell {
        .ant-fullcalendar-month {
            background: $bg-txt-highlight;
            margin: 0;
            .ant-fullcalendar-value {
                color: $txt-tab-link;
                font-family: $font-muli-bold;
            }
        }
    }
    .ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner {
        color: $txt-tab-link;
        font-family: $font-muli-bold;
    }
}
.ant-layout {
    background-color: $white;
    .ant-select-search.ant-select-search--inline .ant-select-search__field {
        width: auto !important;
        box-shadow: initial;
    }
    .ant-layout-header {
        box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.15);
        background: $white;
        padding: 0 3%;
        height: 109px;
        position: relative;
    }
    .ant-layout-footer {
        background: $white;
    }
    &.main-layout {
        background: url('../../assets/images/path-new.png') no-repeat;
        background-size: 100%;
        min-height: auto;
    }
    &.home-layout {
        background: url('../../assets/images/path-new.png') no-repeat;
        background-size: 100%;
        min-height: auto;
        .ant-layout-content {
            max-width: 100%;
        }
    }
    .ant-layout-content {
        object-fit: contain;
        background-size: 100%;
        min-height: auto;
        max-width: 1554px;
        margin: 0 auto;
        width: 100%;
        z-index: 1;
    }
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow-x: inherit;
}

.ant-tabs-nav-scroll {
    text-align: left;
}

.ant-tabs-nav .ant-tabs-tab {
    margin: 0 50px 0 0;
}

.ant-select-selection,
.ant-time-picker-input {
    @include borderRadius(10px);
    height: 40px;
    border: 1px solid $pale-white;
    background-color: $white;
    padding: 4px;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
input:-internal-autofill-selected,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-select-dropdown-menu-item-active {
    background-color: $bg-txt-highlight;
}

.ant-cascader-picker:focus .ant-cascader-input,
.ant-input:focus,
.ant-input:active,
.ant-input:hover,
.ant-select-selection:hover,
.ant-select-selection:active,
.ant-select-focused .ant-select-selection,
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled),
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: $spring-green;
    box-shadow: 0 0 0 2px rgba(171, 220, 162, 0.2);
}

.ant-menu-submenu-title:hover,
.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover,
.ant-breadcrumb a:hover {
    color: $pale-green;
}

.ant-menu-item {
    &:active,
    &:hover,
    &:focus {
        background-color: transparent !important;
    }
}

.ant-anchor-link-active > .ant-anchor-link-title {
    color: $spring-green;
    cursor: pointer;
}

.ant-menu-horizontal > .ant-menu-item > a:hover {
    color: inherit;
}

.ant-table-empty .ant-table-placeholder {
    font-weight: bold;
    color: $black;
    min-height: 70px;
}

.ant-popover-inner-content {
    @include borderRadius(9px);
    box-shadow: 0 -4px 23px 0 rgba(215, 241, 210, 0.88), 0 0 6px 0 rgba(0, 0, 0, 0.52);
    padding: 10px 3px 10px 3px;
    min-width: 150px;
    text-align: left;
}

.ant-table-selection-down .anticon-down svg {
    @include size(0, 0);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 5.5px solid rgba(0, 0, 0, 0.65);
}

.anticon-down.ant-cascader-picker-arrow svg,
.ant-select-arrow .ant-select-arrow-icon svg,
:root .ant-dropdown-trigger > .anticon.anticon-down svg,
:root .ant-dropdown-link > .anticon.anticon-down svg {
    @include size(0, 0);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, 0.65);
}

.ant-form-vertical {
    .ant-form-item-label {
        padding-bottom: 2px;
    }
}

.ant-cascader-picker-label {
    font-family: $font-muli-regular;
    &:hover + .ant-cascader-input {
        border-color: $spring-green;
    }
}

.ant-form-explain.success-message-wrapper {
    text-align: center;
    color: $progressbar-green;
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: $font-weight-bold;
    img {
        width: 20px;
        margin-right: 8px;
    }
}

.ant-form-explain.info-message-wrapper {
    text-align: center;
    color: $progressbar-grey;
    font-size: 18px;
    margin-top: 20px;
    font-weight: $font-weight-bold;
    img {
        width: 20px;
        margin-right: 8px;
    }
}

body #root .ant-table-thead tr th {
    border-radius: 0;
    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    &.ant-table-column-sort {
        background: $dim-white;
    }
    .ant-table-column-sorter .ant-table-column-sorter-inner {
        .ant-table-column-sorter-up.on,
        .ant-table-column-sorter-down.on {
            color: $menu;
        }
    }
}

.ant-menu-vertical {
    border-right: transparent;
}

.ant-time-picker {
    width: 100%;
    input {
        border-radius: 10px;
    }
}

body .ant-modal-content .ant-calendar-picker + .ant-form-item-children-icon {
    margin-top: 10px;
}

body form .ant-radio-wrapper {
    border: 2px dashed rgba(199, 199, 204, 0.55);
    padding: 9px;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 9px;
    color: #616168 !important;
}

.ant-radio-checked {
    .ant-radio-inner {
        border-color: $pale-green;
        background-color: $pale-green;
        &::after {
            background-color: $white;
            top: 4px;
            left: 4px;
            @include size(6px, 6px);
        }
    }
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
    border-color: $pale-green;
}

.ant-checkbox-checked .ant-checkbox-inner {
    border: solid 1px $bg-txt-highlight;
    background-color: $bg-txt-highlight;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid $pale-green;
    border-top: 0;
    border-left: 0;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border: solid 1px $bg-txt-highlight;
}

/**Antd styles - Header menu*/
.ant-menu-horizontal {
    height: 80px;
    border-bottom: none;
    .ant-menu-item:hover,
    .ant-menu-submenu:hover,
    .ant-menu-item-active,
    .ant-menu-submenu-active,
    .ant-menu-item-open,
    .ant-menu-submenu-open,
    .ant-menu-item-selected,
    .ant-menu-submenu-selected {
        color: $menu;
        border-bottom: 2px solid transparent;
    }
}
.ant-menu {
    li:first-child .ant-menu-submenu-title {
        padding: 0;
    }
    .ant-menu-item-selected {
        .menu-item.active-icon {
            display: block;
        }
        .menu-item.no-mob {
            display: none;
        }
    }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $bg-lighter-highlight;
}

.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: rgba(51, 32, 32, 0.65);
}

body {
    form.ant-form {
        input[type='file'] {
            display: none;
        }
        input.ant-input {
            padding: 0 12px;
            height: 40px;
        }
        .ck-editor-block .ant-form-item-control-wrapper .ant-form-item-children {
            display: block;
            .ck-editor-disabled-overlay-container {
                position: absolute;
                width: 100%;
                height: 100%;
                background: #f5f5f5;
                display: block;
                z-index: 10000000;
                opacity: 0.7;
            }
        }

        .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
            border-color: $spring-green;
            box-shadow: 0 0 0 2px rgba(171, 220, 162, 0.2);
        }
        .ant-form-item-required::before {
            display: none;
        }
        label {
            font-size: $font-small-size;
            color: $black;
        }
    }
    .ant-select-selection--single {
        border-radius: 10px;
        height: 42px;
        margin-left: 8px;
        margin-top: 5px;
        .ant-select-selection-selected-value {
            line-height: 2.2;
        }
    }
    .ant-select.ant-select-enabled + .ant-form-item-children-icon {
        top: 14px;
    }
    .ant-calendar-picker-input.ant-input {
        @include borderRadius(10px);
        border: 2px solid #eaeaee;
        height: 41px;
        padding: 0;
    }
    .ant-calendar-range-picker-separator {
        margin-top: 8px;
    }
    footer {
        &.ant-layout-footer {
            @include font($font-xsmall-size, $font-muli-regular);
            color: $black;
        }
    }
    .tab-arrow-link {
        color: $black;
        width: 80%;
        // margin: 0 auto;
        margin-top: 24px;
        @media screen and (min-width: $breakpoint-desktop) {
            margin-top: 24px;
            // margin-left: 60px;
            text-align: left;
        }
        .ant-breadcrumb > {
            span {
                .ant-breadcrumb-separator {
                    @include font(15px, $font-muli-semibold);
                    color: rgba(0, 0, 0, 1);
                }
                .ant-breadcrumb-link {
                    @include font(15px, $font-muli-semibold);
                    a {
                        @include font(15px, $font-muli-semibold);
                        color: rgba(0, 0, 0, 1);
                    }
                }
                &:last-child .ant-breadcrumb-link a {
                    opacity: 0.5;
                    font-family: $font-muli-bold;
                }
            }
        }
    }
    .ant-menu-submenu-popup .ant-menu {
        padding: 40px;
    }
    .ant-badge-status-processing {
        position: absolute;
    }
    /**Antd modal */
    .ant-modal-body {
        text-align: center;
        p {
            font-size: $font-smallToMed-size;
            margin-top: 26px;
        }
    }
    .ant-modal-footer {
        padding: 0;
        div {
            padding: 10px 16px;
        }
    }
    .ant-modal-confirm {
        .ant-modal-confirm-body {
            text-align: center;
            i {
                float: none;
                font-size: 111px;
                margin-right: 0;
            }
            .ant-modal-confirm-title {
                @include font(24px, $font-muli-semibold);
                width: 100%;
                margin: 0 auto;
                margin-top: 15px;
                line-height: 1.13;
                overflow: inherit;
            }
            .ant-modal-confirm-content {
                @include font(15px, $font-muli-regular);
                width: 100%;
                text-align: center;
                margin: 0 auto;
                margin-top: 26px;
                margin-left: 0 !important;
                color: $black;
            }
        }
        .ant-btn {
            padding: 0 25px;
            @include buttonSpec($spring-green, $white, $mild-spring-green);
            &:hover {
                @include buttonSpec($spring-green, $white, $mild-spring-green);
            }
        }
        .ant-btn-primary {
            @include font($font-smallToMed-size, $font-muli-bold);
            @include size(auto, 40px);
            @include borderRadius(21.5px);
            @include buttonSpec(transparent, $spring-green, $white);
            padding: 0 35px;
            &:hover {
                @include buttonSpec($spring-green, $white, $spring-green);
            }
        }
    }
}

/* Responsiveness */
@media (max-width: $breakpoint-smphone) {
    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
        display: inline-block;
        position: relative;
        width: 33%;
    }
}

@media screen and (max-width: $breakpoint-xlarge) {
    .ant-layout {
        background-size: 100%;
    }
}

@media screen and (max-width: $breakpoint-lgphone) {
    .ant-menu-submenu-popup {
        left: 0 !important;
        width: 100%;
        .ant-menu {
            background-color: $bg-submenu;
            li {
                width: 50%;
                text-align: center;
                display: inline-block;
                padding: 40px 0;
            }
        }
    }
}

@media screen and (min-width: 1441px) {
    .ant-layout {
        background-position: top;
        background-size: 100% 68%;
    }
}
