.card-list {
    .card {
        border: 1px solid rgba(199, 199, 204, 0.55);
        padding: 9px;
        border-radius: 10px;
        margin-bottom: 9px;
        color: #616168 !important;
        width: 48%;
        position: relative;
        min-height: 171px;
        @media screen and (min-width: $breakpoint-mdmdesktop) {
            display: inline-block;
            &:nth-child(2n) {
                float: right;
            }
        }
        @media screen and (max-width: $breakpoint-lgtablet) {
            width: 62%;
        }
        @media screen and (max-width: $breakpoint-smphone) {
            width: 100%;
        }
        .card-info {
            display: flex;
            flex-direction: column;
        }
        .card-action-wrapper {
            display: flex;
            justify-content: space-around;
            width: 73px;
            position: absolute;
            top: 14px;
            right: 9px;
        }
        .right-align {
            width: auto;
            @media screen and (max-width: $breakpoint-smphone) {
                width: 100%;
                text-align: left;
                margin-bottom: 25px;
            }
        }
        .card-field {
            width: 100%;
            &.card-number {
                margin: 20px 0;
                div {
                    @include font($font-medium-size, $font-muli-regular);
                    color: #2e2e30;
                }
            }
            label {
                @include font(10px, $font-didact-regular);
                text-transform: uppercase;
                letter-spacing: 1.67px;
                color: #8e8e93;
            }
            .field-text {
                @include font($font-small-size, $font-didact-regular);
                color: #2e2e30;
                max-width: 250px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &:last-child {
                margin-bottom: 5px;
            }
        }
        .ant-radio-wrapper {
            position: relative;
            border: transparent;
            .card-details {
                .cvv-field {
                    min-width: 62px;
                }
            }
            &.ant-radio-wrapper .right-align {
                font-size: $font-small-size;
            }
        }
        .ant-radio-group.ant-radio-group-outline {
            width: 100%;
        }
    }
}

.add-card-form {
    border-radius: 10px;
    background-color: #f9f9fb;
    padding: 23px;
}