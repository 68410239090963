.ant-menu-inline-collapsed {
    .sidebar-title {
        display: none !important;
    }
}
.admin-sidebar-menu-item {
    height: auto !important;
    .ant-menu-submenu-arrow {
        top: 10px;
    }
    .sidebar-title {
        display: inline-block;
        float: left;
        width: auto;
        overflow-wrap: break-word;
        margin-left: 10px !important;
        white-space: normal;
        word-break: break-word;

        @media (min-width: 768px) {
            max-width: 100px;
            line-height: 20px;
        }
    }
}
