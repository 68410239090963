body {
    #root {
        .auto-width {
            width: auto;
        }
        .listing-wrapper {
            .ant-input-search {
                min-width: 250px;
                margin-top: 12px;
            }
            .ant-table-wrapper {
                margin-top: 15px;
                width: 100%;
            }
        }
        form .template-textarea {
            .ant-form-item {
                margin-bottom: 0;
            }
            textarea {
                min-height: 130px;
            }
        }
        .login-action-wrapper a {
            color: $pale-green !important;
        }
        .with-pre-tab {
            .ant-form-item {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}
