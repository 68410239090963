button.ant-btn,
label.upload-btn {
    @include font($font-smallToMed-size, $font-muli-bold);
    @include size(auto, 40px);
    text-align: center;
    @include borderRadius(21.5px);
    &.big-btn {
        @include size(auto, 43px);
        padding: 8px 60px;
        text-align: center;
    }
    &.green-bg-button,
    &.green-bg-button:focus {
        @include buttonSpec(transparent, $spring-green, $white);
    }
    &.white-bg-button,
    &.white-bg-button:focus {
        @include buttonSpec($spring-green, $white, $mild-spring-green);
    }
    &.green-bg-button:hover {
        @include buttonSpec($spring-green, $white, $spring-green);
        &:disabled:hover {
            @include buttonSpec(transparent, $spring-green, $white);
        }
    }
    &.white-bg-button:hover {
        @include buttonSpec($spring-green, $spring-green, $white);
        &:disabled:hover {
            @include buttonSpec($spring-green, $white, $mild-spring-green);
        }
        i {
            color: $white;
        }
    }
    &.blue-bg-button,
    &.blue-bg-button:focus {
        @include buttonSpec($bg-blue-btn, $bg-blue-btn, $txt-blue-btn-text);
        @include font($font-small-size, $font-muli-semibold);
        padding: 10px 26px;
        &:hover {
            @include buttonSpec($bg-blue-highlight, $bg-blue-highlight, $txt-blue-btn-text);
        }
    }
    &:hover {
        box-shadow: none;
        transform: initial;
    }
}

body {
    .ant-upload.ant-upload-drag {
        background: $white;
        border: 1px solid $login-linebreak-grey;
        border-radius: 8px;
        .ant-upload-btn {
            padding: 0;
            p {
                margin-top: 0;
                padding: 7px;
                margin-bottom: 0;
                font-size: 15px;
                text-align: left;
                padding-left: 16px;
                &.ant-upload-text {
                    float: left;
                    width: 78%;
                }
            }
            .ant-upload-drag-icon {
                width: 5%;
                float: right;
                margin-left: 21px;
                .anticon {
                    float: right;
                    svg {
                        @include size(25px, 25px);
                        margin-right: 14px;
                        color: $spring-green;
                    }
                }
            }
            .ant-upload-text {
                color: $light-grey;
                font-size: 14px;
            }
        }
    }
    .medium-btn {
        width: 95px !important;
        float: right;
    }
    .preview-button {
        float: right;
        margin-right: 14px;
    }
    .preview-placeholder-button-holder {
        z-index: 9999999 !important;
    }
}

.ant-form {
    .white-bg-button.choice-btn {
        @include size(190px, 68px);
        @include font($font-small-size, $font-muli-regular);
        @include buttonSpec($pale-white, $white, $black);
        @include borderRadius(10px);
        &:hover {
            @include buttonSpec(transparent, $spring-green, $white);
            box-shadow: 0 3px 16px 0 rgba(101, 202, 99, 0.5);
            font-family: $font-muli-semibold;
        }
    }
}
