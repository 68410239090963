body .white-box-wrapper {
    .settings-edit-form {
        width: 77%;
        float: right;
        padding: 50px 46px 34px 46px;
        text-align: left;
        @media screen and (min-width: $breakpoint-desktop) {
            border-left: 2px solid $menu-break;
            min-height: 512px;
        }
        @media screen and (max-width: $breakpoint-lgtablet) {
            width: 100%;
            padding: 0 11px 34px 11px;
        }
        header {
            @include font($font-mLarge-size, $font-muli-regular);
            color: $black;
            margin-bottom: 15px;
        }
        .ant-select-selection--single {
            margin-top: 0;
        }
        form {
            width: 100%;
            padding: 0;
            ul {
                .form-row {
                    &:first-child {
                        padding: 0 46px;
                    }
                    padding-top: 0;
                }
            }
            .edit-add-profile-pic {
                bottom: 9px;
                background-color: $border-grey;
                color: $black;
                font-family: $font-muli-semibold;
            }
        }
        .profile-detail-upper {
            padding-left: 0;
            padding-bottom: 0;
        }
        .edit-form-update {
            text-align: center;
        }
        .upper-right-detail {
            padding-top: 0;
        }
        .profile-pic-edit img {
            border: 9px solid $border-grey;
        }
    }
    .settings-menu {
        width: 23%;
        display: inline-block;
        float: left;
        .settings-list-wrapper {
            .menu-header .settings-menu-open {
                transform: rotate(90deg);
                margin-top: -27px;
            }
            .right-arrow {
                padding: 9px;
                border-radius: 50%;
                float: right;
                background-color: $border-grey;
            }
            .menu-header {
                @include font($font-medium-size, $font-muli-regular);
                text-align: left;
                border-bottom: 2px solid $menu-break;
                padding: 25px 20px 25px 46px;
                color: $black;
            }
            ul {
                display: block;
                li {
                    text-align: left;
                    border-bottom: 2px solid $menu-break;
                    padding: 16px 20px 16px 46px;
                    .right-arrow-selected {
                        display: none;
                    }
                    &.active-menu {
                        background-color: $bg-menu-highlight;
                        .right-arrow {
                            background-color: $pale-green;
                            &.right-arrow-selected {
                                display: block;
                            }
                            &.right-arrow-unselected {
                                display: none;
                            }
                        }
                    }
                    &.search {
                        padding: 21px 24px 25px 46px;
                        input,
                        input:hover,
                        input:focus {
                            border: transparent;
                            box-shadow: none;
                            padding: 0;
                            font-size: $font-small-size;
                            background: url('../../assets/images/search.png') no-repeat;
                            background-position: right center;
                        }
                        .ant-input-suffix {
                            display: none;
                        }
                    }
                    &:before {
                        display: none;
                    }
                    &.menu {
                        line-height: 2;
                        .header {
                            @include font($font-smallToMed-size, $font-muli-regular);
                            color: $black;
                        }
                        .header-details {
                            @include font(13px, $font-muli-regular);
                        }
                        &:hover {
                            background-color: $bg-menu-highlight;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .ant-collapse-item-active {
        .ant-collapse-arrow {
            transform: rotate(90deg) !important;
            margin-top: -5px;
        }
    }
    .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-body {
            i {
                width: 100%;
                svg {
                    @include size(111px, 111px);
                    margin-bottom: 40px;
                }
            }
            .ant-modal-confirm-title {
                font-family: $font-muli-bold;
            }
            .ant-modal-confirm-title,
            .ant-modal-confirm-content {
                display: block;
                width: 100%;
                margin: 0 auto;
                margin-left: 0 !important;
                p {
                    margin-top: 4px;
                }
            }
        }
        .ant-modal-confirm-btns {
            button {
                background-color: $mild-spring-green !important;
                border: initial;
                width: 80px;
                &:hover {
                    background-color: $spring-green !important;
                }
            }
        }
    }
}

/**Responsiveness */
@media screen and (max-width: $breakpoint-lgphone) {
    body #root {
        .white-box-wrapper {
            .settings-edit-form,
            .settings-menu {
                border-left: none;
                width: 100%;
                padding: 10px;
            }
            .settings-edit-form {
                header {
                    text-align: center;
                }
                .profile-pic-edit {
                    margin: 0 auto;
                    float: none;
                    padding-left: 0;
                    .edit-add-profile-pic {
                        left: 2px;
                    }
                }
                .details-wrapper {
                    margin-top: 10px;
                }
                .ant-col {
                    width: 100%;
                }
                .form-row {
                    padding: 0;
                }
            }
        }
    }
}
@media screen and (max-width: $breakpoint-lgtablet) {
    body {
        #root .settings-wrapper {
            .mob-only {
                display: block;
                margin: 0 auto;
                .collapsible-settings-menu {
                    width: 100%;
                    margin: 0 auto;
                    border: transparent;
                    .ant-collapse-item {
                        background-color: $white;
                    }
                }
            }
            .no-mob {
                display: none;
            }
        }
        .ant-collapse-item-active {
            .ant-collapse-header {
                background-color: rgba(236, 236, 236, 0.6);
            }
        }
    }
}
@media screen and (min-width: $breakpoint-desktop) {
    body {
        .white-box-wrapper .settings-menu .settings-list-wrapper {
            .menu-header .settings-menu-open {
                display: none !important;
            }
            ul {
                display: block !important;
            }
        }
        #root .settings-wrapper {
            .mob-only {
                display: none;
            }
            .no-mob {
                display: block;
            }
        }
    }
}
@media screen and (max-width: 1399px) {
    body #root .white-box-wrapper {
        .settings-edit-form form {
            .profile-pic-edit {
                padding-left: 0;
                .edit-add-profile-pic {
                    left: 4px;
                    bottom: -11px;
                }
            }
            .form-row {
                padding-left: 0;
            }
        }
        .settings-menu .settings-list-wrapper {
            ul li,
            .menu-header {
                padding: 16px 25px;
            }
        }
    }
}
