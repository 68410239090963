/**Datepicker */
.ant-calendar-picker {
    float: left;
    .ant-calendar-picker-input {
        text-align: left;
    }
    .ant-calendar-range-picker-input {
        width: 38%;
        text-align: left;
        margin-left: 6px;
        display: inline-block;
        padding: 0;
    }
    .ant-calendar-range-picker-separator + .ant-calendar-range-picker-input {
        text-align: right;
    }
}
