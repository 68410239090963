@mixin borderRadius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin font($size: '16px', $family: 'Muli SemiBold') {
    font-size: $size;
    font-family: $family, sans-serif;
}

@mixin size($width: '0', $height: '0') {
    width: $width;
    height: $height;
}

@mixin stepDot() {
    border: none;
    @include size(15px, 15px);
    margin-right: 0;
    box-shadow: 0 2px 8px 0 rgba(101, 202, 99, 0.5);
    background-color: $progressbar-green;
    .ant-steps-icon {
        color: transparent;
    }
}

@mixin buttonSpec($borderColordth: transparent, $bgColor: transparent, $textColor: transparent) {
    border: 1px solid $borderColordth;
    background-color: $bgColor;
    color: $textColor;
}

@mixin semiCircle($size: 46px) {
    background-color: rgba(0, 0, 0, 0.36);
    display: inline-block;
    margin: 0 1em 1em 0;
    height: $size;
    width: $size * 2;
    border-radius: 0;
    border-bottom-left-radius: $size * 2;
    border-bottom-right-radius: $size * 2;
}
