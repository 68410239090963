@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/variables';

body #root {
    .invoice-details-wrapper {
        margin-top: 0;
        .details-profile-pic-wrapper img {
            width: auto;
        }
        .reminders-header {
            width: 100%;
        }
        .reminders-wrapper {
            .ant-tabs-bar {
                padding: 16px 10px 0 10px;
            }
            .ant-table-wrapper {
                padding: 0 15px;
            }
            .align-right {
                margin-bottom: 6px;
            }
        }
    }
}
