.search-filter-export-wrapper {
    button.ant-btn.big-btn {
        padding: 8px 30px;
        margin-top: 8px;
        &.ant-dropdown-trigger {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    .ant-input-search.ant-input-affix-wrapper {
        width: 100%;
    }
    .right-menu-wrapper {
        text-align: left;
        button {
            margin: 10px;
        }
        .right-align {
            width: auto;
        }
    }
    .selector-field {
        display: inline-grid;
        margin: 0;
        grid-gap: 5px;
        @media screen and (max-width: $breakpoint-smphone) {
            grid-template-columns: 1fr;
        }
        li {
            &:first-child {
                padding-left: 0;
            }
            &::before {
                height: 30px;
                margin-right: -26px;
            }
            &:not(:first-child)::before {
                display: none;
            }
        }
        .selector-title {
            @include font($font-small-size, $font-muli-regular);
            margin-bottom: 5px;
            color: $black;
            text-align: left;
        }
        span.ant-cascader-picker {
            width: 100%;
        }
        &.show-elem {
            display: inline-grid;
        }
        &.hide-elem {
            display: none;
        }
    }
    hr {
        margin-bottom: 18px;
    }
    .filter-search-btn {
        display: none;
        margin-top: 15px;
    }
}

/**Responsiveness */
@media screen and (max-width: $breakpoint-lgphone) {
    .listing-wrapper {
        .filter-search-btn {
            display: block;
        }
        .selector-field {
            display: none;
            li {
                padding-left: 0;
                padding-bottom: 0;
                display: inline-block;
            }
            &.show-elem {
                display: block;
                text-align: center;
            }
            li::before {
                display: none;
            }
        }
    }
}

@media screen and (min-width: $breakpoint-tablet) {
    .listing-wrapper {
        .selector-field {
            display: inline-grid !important;
            li:last-child {
                padding-right: 0;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-mdphone) {
    .listing-wrapper {
        .right-menu-wrapper {
            text-align: center;
            button {
                margin-left: 0;
            }
            .right-align {
                float: none;
                width: 100%;
                text-align: center;
                margin-top: 15px;
                button {
                    margin-bottom: 15px;
                }
            }
        }
        .filter-search-btn {
            margin: 0 auto;
            margin-top: 15px;
        }
        .ant-input-search.ant-input-affix-wrapper {
            width: 90%;
            margin: 0 auto;
        }
    }
}
