body #root {
    .mid-wrapper {
        overflow: auto;
    }
    .account-details-list {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
        li {
            padding: 12px !important;
            &.long-list-item {
                width: 100% !important;
            }
        }
    }
    .details-list {
        display: inline-block;
        padding: 5px 0;
        .full-wrapper {
            color: $black;
            padding-left: 11px;
        }
        .detail-summary ul {
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            li {
                padding: 15px 11px;
            }
        }
        .full-length-list {
            display: block;
        }
        .profile-name {
            margin-bottom: 25px;
        }
    }
    .details-profile-pic-wrapper {
        @include size(225px, 220px);
        // padding: 30px;
        margin: 0 auto;
        border: 9px solid $border-grey;
        margin-bottom: 24px;
        max-width: 220px;
        padding: 0;
        overflow: hidden;
        position: relative;
        img {
            @include size(auto, auto);
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            padding: 5px;
        }
    }
}

/**Responsiveness */
@media screen and (max-width: $breakpoint-lgtablet) {
    body #root {
        .details-list,
        .left-align {
            width: 100%;
            text-align: center;
        }
        .full-wrapper {
            @include font(28px, $font-muli-bold);
        }
    }
}
@media screen and (min-width: $breakpoint-desktop) {
    body #root {
        .details-list {
            width: 62%;
            &.left-align {
                text-align: center;
                width: 35%;
            }
        }
        .full-wrapper {
            @include font(34px, $font-muli-bold);
        }
    }
}
