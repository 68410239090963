@import '../../../../styles/abstracts/variables';
@import '../../../../styles/abstracts/mixins';

@media screen and (min-width: $breakpoint-mdmdesktop) {
    body {
        ul.home-menu-wrapper {
            &.right-nav {
                width: 22%;
                float: right;
                max-height: 123px;
                .profile-detail {
                    float: right;
                    padding-bottom: 5px;
                    img {
                        margin-top: 0;
                    }
                    .profile-name {
                        margin-top: -44px;
                    }
                }
                li {
                    padding-right: 15px;
                    &:last-child {
                        padding-right: 0;
                        .menu-popover-wrapper {
                            float: left;
                        }
                    }
                }
            }
        }
    }
}
