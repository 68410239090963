@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

body {
    .admin-bg {
        background: $bg-admin;
        position: relative;
    }
    .admin-main-menu {
        padding: 8px 15px;
        span {
            cursor: pointer;
        }
    }
    #root {
        .calender-wrapper {
            .align-right {
                position: relative;
            }
            .ant-btn.ant-btn-link {
                padding: 0;
                padding-right: 5px;
                &:last-child {
                    padding-right: 0;
                }
            }
            .separator {
                width: 1px;
                height: 14px;
                content: '';
                color: #595964;
                float: right;
                margin: 0 8px;
                background-color: #afafb9;
                margin-top: 4px;
                position: absolute;
            }
        }
        .admin-label {
            @include font($font-smallToMed-size, $font-muli-semibold);
            color: $black;
            padding-right: 20px;
        }
        .admin-crud-spec {
            width: 92%;
            margin: 0 auto;
            padding-top: 12px;
        }
        .admin-header {
            ::placeholder {
                color: $white;
                opacity: 1; /* Firefox */
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $white;
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $white;
            }
            h1 a {
                margin: 40px 0 40px 15px;
            }
            .notification-menu.profile-detail img {
                margin-top: 0 !important;
            }
        }
        .hamburger-admin-icon {
            @include size(42px, 42px);
            background-color: $bg-admin;
        }
        .admin-login-content .ant-layout-content {
            background: initial;
        }
        .admin-content {
            background: $bg-admin;
            .admin-sidebar-custom {
                // z-index: 2;
                @media screen and (max-width: $breakpoint-lgphone) {
                    position: absolute;
                    z-index: 9;
                    background: rgba(0, 0, 0, 0.9);
                    top: 85px;
                    &.ant-layout-sider-collapsed {
                        left: -85px !important;
                    }
                }
            }
            .dashboard-wrapper .count-wrapper ul {
                grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
                .count-info {
                    word-break: keep-all;
                }
            }
            .my-profile-detail {
                @media screen and (max-width: $breakpoint-smphone) {
                    .profile-detail-upper .upper-right-detail li {
                        padding-top: 5px;
                    }
                    .details-wrapper {
                        margin-top: 0;
                    }
                    .profile-pic-custom {
                        @include size(110px, 110px);
                    }
                }
            }
            .ant-layout-header {
                @include size(35px, 12px);
                box-shadow: none;
                padding: 0 17px !important;
                z-index: 999;
                background: transparent !important;
                .anticon {
                    @include size(35px, 39px);
                    padding: 10px;
                    top: -66px;
                    left: 0;
                    position: absolute;
                    background: $menu !important;
                    color: $white;
                }
            }
            .profile-pic-edit {
                @media screen and (max-width: $breakpoint-lgphone) {
                    margin-left: 0;
                }
                button {
                    border: none;
                    font-size: 14px;
                    &:hover {
                        background: transparent;
                        color: $pale-green;
                        text-decoration: underline;
                    }
                }
            }
            .ant-layout-content {
                background: initial;
                background: $bg-admin;
                // z-index: 3;
                overflow-x: hidden;
                .ant-input-search.ant-input-affix-wrapper {
                    width: 65%;
                    max-width: 450px;
                    min-width: 250px;
                    @media screen and (max-width: $breakpoint-smphone) {
                        min-width: 250px;
                    }
                }
                .breadcrumb-spec {
                    margin-left: 30px;
                }
                .settings-edit-form {
                    width: 100%;
                    border-left: none;
                    padding: 50px 0 36px 0;
                    .right-align {
                        width: auto;
                    }
                }
                .white-box-wrapper.full-wrapper {
                    width: 97%;
                }
                .accounts-table-admin {
                    tr th:first-child,
                    tr td:first-child {
                        width: 400px;
                        min-width: 300px;
                        white-space: inherit;
                    }
                    & + .custom-scrollble-table-collapse .details-list .detail-summary ul {
                        grid-template-columns: repeat(1, minmax(70px, 1fr)) !important;
                    }
                }
                @media screen and (max-width: 991px) {
                    .ant-table-wrapper {
                        overflow-x: auto;
                    }
                }
            }
            h2.page-title {
                display: none;
            }
            h3.page-title {
                width: 100%;
                font-size: 25px;
                text-transform: capitalize;
            }
            .custom-menu-selected {
                .active-icon {
                    padding: 10px 4px 10px 1px;
                }
                .title {
                    color: $pale-green !important;
                }
                img.active-icon {
                    display: block;
                }
                img.no-mob {
                    display: none;
                }
            }
            .ant-layout-sider {
                background: $bg-admin;
                padding-top: 0;
                padding-right: 12px;
                z-index: 11;
                @media screen and (min-width: $breakpoint-tablet) {
                    margin-left: -36px;
                }
                @media screen and (max-width: $breakpoint-lgphone) {
                    margin-left: -11px;
                    background: rgba(219, 219, 226, 1);
                    width: 103% !important;
                    max-width: 103% !important;
                    &.ant-layout-sider-collapsed {
                        width: auto !important;
                        max-width: 100% !important;
                    }
                }
                .home-sidebar-wrapper {
                    li {
                        min-height: 47px;
                        margin: 0;
                        color: $black;
                        @media screen and (max-width: $breakpoint-lgphone) {
                            padding: 0 25px;
                            border-bottom: 1px solid $plain-grey;
                        }
                        &.no-mob {
                            @media screen and (min-width: $breakpoint-tablet) {
                                display: none !important;
                            }
                        }
                        .ant-menu-submenu-title {
                            margin: 0;
                            @media screen and (max-width: $breakpoint-lgphone) {
                                padding-left: 0 !important;
                            }
                        }
                        .title {
                            color: $black;
                            margin-left: 34px;
                        }
                        .icon-bg {
                            display: flex;
                            align-items: center;
                        }
                        img.menu-item.no-mob {
                            @include size(25px, 21px);
                        }
                        ul li {
                            .ant-menu-item {
                                padding-left: 58px !important;
                            }
                        }
                    }
                    .admin-menu {
                        height: 100%;
                        border-right: 0;
                        @media screen and (min-width: $breakpoint-tablet) {
                            padding-top: 30px;
                        }
                    }
                }
            }
            .white-box-wrapper {
                margin-top: 15px !important;
                @media screen and (max-width: $breakpoint-lgphone) {
                    width: 98%;
                    padding: 34px 15px;
                }
            }
        }
        .admin-footer-wrapper {
            background: $bg-admin;
            // position: absolute;
            bottom: 0;
            z-index: 9;
        }
        .ant-menu-inline .ant-menu-item::after {
            border-right: none;
        }
        .admin-content .ant-layout-sider .home-sidebar-wrapper li.holiday-calendar-icon {
            height: auto;
            span.title {
                line-height: 25px;
                white-space: normal;
            }
        }
        .admin-content .ant-layout-sider .home-sidebar-wrapper li.custom-menu-selected {
            background-color: $txt-detail;
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
            &:hover {
                background-color: $txt-detail !important;
                border-top-right-radius: 100px;
                border-bottom-right-radius: 100px;
            }
            @media screen and (max-width: $breakpoint-lgphone) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                &:hover {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            .title {
                color: $white !important;
                margin-top: 3px;
                font-family: $font-muli-semibold;
            }
        }
        .ant-menu {
            background: transparent;
        }
        .admin-pic-edit {
            margin-bottom: 35px;
            line-height: 0.8;
            overflow: hidden;
            .edit-add-profile-pic {
                @include font($font-small-size, $font-muli-regular);
                border: 1px solid $pale-white;
                color: $black;
                border-radius: 0;
                vertical-align: top;
                margin-top: 2px;
            }
            .wrapper {
                width: 69%;
                float: right;
            }
        }
    }
}
