.my-profile-detail {
    .profile-detail-upper {
        padding: 30px 7px;
        display: inline-grid;
        grid-template-columns: 1fr 5fr;
        text-align: left;
        grid-column-gap: 10px;
        img {
            @include size(100%, auto);
            border-radius: 50%;
        }
        .circular--landscape-big img {
            @include size(auto, auto);
            border-radius: 0;
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .profile-pic {
            border: 1px solid $pale-white;
        }
        .upper-right-detail {
            display: initial;
            li {
                display: block;
                padding: 15px 22px 5px 1%;
                text-align: left;
                @media screen and (min-width: 1400px) {
                    &:first-child {
                        // padding: 25px 22px 25px 7%;
                    }
                }
                .profile-name {
                    @include font(34px, $font-muli-bold);
                    line-height: 1.2;
                    @media screen and (max-width: $breakpoint-lgtablet) {
                        @include font(24px, $font-muli-bold);
                    }
                    @media screen and (min-width: $breakpoint-desktop) and (max-width: $breakpoint-large) {
                        @include font(30px, $font-muli-bold);
                    }
                    color: $black;
                    margin: 0;
                }
                img {
                    @include size(18px, 16px);
                    border-radius: 0;
                    vertical-align: middle;
                }
                .profile-label {
                    @include font($font-smallToMed-size, $font-muli-regular);
                    color: $black;
                    padding: 0 12px;
                }
                button {
                    height: 27px;
                    font-size: $font-xsmall-size;
                    margin-top: 7px;
                }
                &::before {
                    display: none;
                }
                &.verify {
                    img {
                        border: transparent;
                    }
                }
            }
        }
    }
    hr {
        margin: 0;
        border: transparent;
        border-bottom: 2px dashed #ebebeb;
    }
    .profile-pic-edit {
        position: relative;
        @include size(254px, 254px);
        display: inline-grid;
        .ant-btn-link {
            padding-bottom: 6px;
            color: $red;
            &:hover {
                color: $red;
                span {text-decoration: underline;}
            }
        }
        .edit-add-profile-pic {
            position: absolute;
            border: transparent;
            background-color: rgba(0, 0, 0, 0.36);
            color: $white;
            &:hover,
            &:active,
            &:focus {
                border: transparent;
                background-color: rgba(0, 0, 0, 0.36);
                color: $white;
            }
            &:hover {
                background-color: rgba(0, 0, 0, 0.5);
            }
        }
    }
    form .profile-detail-upper {
        padding-bottom: 0;
        ul.upper-right-detail li {
            padding-bottom: 8px;
        }
    }
}

body {
    .edit-form {
        .ant-col {
            text-align: left;
        }
        .react-phone-number-input__country, .PhoneInputCountrySelect {
            width: 7%;
            min-width: 40px;
            .react-phone-number-input__icon, .PhoneInputCountryIcon  {
                min-width: 28px;
            }
        }
        .react-phone-number-input__country-select-arrow, .PhoneInputCountrySelectArrow {
            margin-top: 5px;
        }
        .react-phone-number-input input, .PhoneInput input {
            padding-right: 8px !important;
            box-shadow: none;
        }
        .edit-form-update {
            text-align: center;
        }
    }
    .edit-profile-wrapper {
        margin-bottom: 20px;
        h2.page-title {
            margin-top: 10px;
        }
        hr {
            margin: 0;
        }
    }
    .profile-detail-lower {
        padding: 25px 22px;
        padding-left: 2%;
        @media screen and (max-width: $breakpoint-lgtablet) {
            padding: 0 4px;
        }
    }
}

/**Responsiveness */
@media screen and (min-width: $breakpoint-xlarge) {
    .my-profile-detail {
        form ul.upper-right-detail li {
            padding-top: 0;
        }
        .profile-detail-upper {
            .upper-right-detail li {
                text-align: left;
                width: 72%;
                max-width: 520px;
                button {
                    float: right;
                    height: 27px;
                    font-size: $font-xsmall-size;
                    margin-top: 2px;
                    position: absolute;
                    left: 73%;
                    margin-left: 0;
                    + button {
                        left: 93%;
                    }
                }
                &::before {
                    display: none;
                }
                &.verify {
                    position: relative;
                    img {
                        border: transparent;
                    }
                }
                .profile-name {
                    text-align: left;
                }
            }
        }
    }
    .profile-pic-edit {
        .edit-add-profile-pic {
            background-color: rgba(0, 0, 0, 0.36);
            border-radius: 0;
            display: inline-block;
            margin: 0 1em 1em 0;
            height: 50px;
            width: 254px;
            bottom: -16px;
            left: 0;
        }
    }
}
@media screen and (max-width: 1399px) {
    body .my-profile-detail {
        .profile-detail-upper {
            padding-top: 0;
            .upper-right-detail li button {
                margin-left: 8px;
                margin-right: 0;
            }
            img {
                @include size(190px, 190px);
            }
        }
        .profile-pic-edit {
            @include size(192px, 230px);
            text-align: left;
            padding-left: 0;
            float: left;
            margin-right: 15px;
            .edit-add-profile-pic {
                border-radius: 0;
                display: inline-block;
                height: 50px;
                width: 192px;
                bottom: 38px;
                left: 0;
            }
        }
    }
}
@media screen and (max-width: $breakpoint-lgtablet) {
    body #root .my-profile-detail {
        .mrgn-tp-25 {
            margin-top: 0;
        }
        .profile-detail-upper {
            padding: 0 0 10px 0;
            .upper-right-detail li {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .profile-detail-lower .ant-col {
            width: 100%;
        }
    }
}
@media screen and (max-width: $breakpoint-mdphone) {
    body #root .my-profile-detail {
        .profile-detail-upper {
            .upper-right-detail li button {
                display: initial;
                margin-top: 12px;
            }

            .profile-pic-edit {
                float: none;
                text-align: center;
                display: block;
                margin: 0 auto;
            }
        }
    }
}
@media screen and (max-width: 676px) {
    .my-profile-detail {
        .profile-detail-upper {
            display: block;
        }
    }
}
