#root .details-wrapper {
    position: relative;
    .white-box-wrapper ul li {
        padding: 25px 11px;
    }
    .sub-detail {
        font-size: $font-small-size;
        margin-bottom: 20px;
    }
    header {
        .account-name {
            width: 30%;
            display: inline-block;
            margin-top: 20px;
            p {
                @include font(24px, $font-muli-semibold);
                color: $black;
            }
            .sub-detail {
                @include font($font-xsmall-size, $font-muli-semibold);
                color: $txt-sub-content;
                margin-top: 5px;
                text-indent: 20px;
                margin-bottom: 18px;
                &.calendar-bg {
                    background: url('../../assets/images/calendar.png') no-repeat;
                }
            }
        }
        .more-dropdwn {
            width: 17%;
            display: inline-block;
            margin-top: 22px;
            min-width: 150px;
            input {
                margin: 18px 0;
            }
        }
    }
    hr {
        border: 2px solid $menu-break;
        margin-bottom: 18px;
    }
    .detail-summary {
        ul {
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
            li {
                display: inline-block;
                text-align: left;
                width: 100%;
                &::before {
                    display: none;
                }
                &.long-list-item {
                    width: 40%;
                }
                header {
                    @include font($font-small-size, $font-muli-bold);
                    color: $txt-detail;
                    text-transform: uppercase;
                }
                div {
                    @include font($font-small-size, $font-muli-regular);
                    color: $black;
                    margin-top: 3px;
                    line-height: 1.3;
                }
            }
        }
    }
    .ant-avatar {
        position: absolute;
        top: -50px;
        z-index: 2;
        left: 46%;
        right: 0;
        @include size(100px, 100px);
        border: 2px solid $white;
        border-radius: 60px;
        box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.15);
    }
    &.synced-name .details-list {
        h3.profile-name {
            margin-bottom: 0;
            line-height: 1.5;
            span {
                margin-left: 15px;
            }
        }
    }
}

/**Responsiveness */
@media screen and (max-width: $breakpoint-smphone) {
    #root .details-wrapper {
        .detail-summary ul {
            grid-template-columns: repeat(1, minmax(120px, 1fr)) !important;
            li {
                &.long-list-item {
                    width: 96%;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-lgphone) {
    #root .details-wrapper {
        .detail-summary {
            ul {
                grid-template-columns: repeat(2, minmax(120px, 1fr));
                li {
                    &.long-list-item {
                        width: 96% !important;
                    }
                }
            }
        }
        .details-list .detail-summary ul li {
            text-align: center;
        }
    }
}

@media screen and (max-width: $breakpoint-mddesktop) {
    #root .details-wrapper {
        margin-top: 20px;
        header {
            width: 100%;
            overflow: initial;
            .account-name {
                display: block;
                width: 100%;
            }
        }
    }
}
