.phone-field-wrapper {
    position: relative;
    .ant-col,
    .ant-form-item-control,
    .ant-form-item-children {
        position: initial !important;
    }
    .react-phone-number-input, .PhoneInput {
        border: 1px solid $login-linebreak-grey;
        @include borderRadius(10px);
        .react-phone-number-input__icon, .PhoneInputCountryIcon  {
            padding-top: 5px !important;
            padding-top: 5px;
            margin-left: 7px;
            width: auto;
            display: block;
            min-width: 25px;
            border: none;
            height: 40px;
            svg {
                height: 22px;
                padding: 2px;
            }
        }
        input {
            border-bottom: none;
            padding-right: 32px;
            box-shadow: none;
            border: none;
            outline: none;
        }
    }
    .has-success.has-feedback .ant-form-item-children-icon,
    .has-error.has-feedback .ant-form-item-children-icon {
        position: absolute;
        right: 0;
        top: 20px;
    }
    .react-phone-number-input__icon-image, .PhoneInputCountryIconImg {
        width: 28px !important;
        height: 28px !important;
        display: inline;
        vertical-align: middle;
    }
}
