/**Step */
body {
    .ant-steps-item-content {
        display: block;
    }

    .ant-steps-item {
        width: 33.33%;
    }

    .ant-steps-item-title {
        @include font($font-xsmall-size, $font-muli-semibold);
        padding-right: 0;
        &::after {
            top: -9px;
            height: 2px;
            left: 71%;
        }
        &::before {
            @include size(9999px, 2px);
            content: '';
            top: -9px;
            position: absolute;
            display: block;
            background: $dim-grey;
            right: 71%;
        }
    }

    .ant-steps-item-process {
        .ant-steps-item-content {
            .ant-steps-item-title {
                &::after,
                &::before {
                    background-color: $spring-green;
                }
                &::before {
                    background-color: $spring-green;
                }
            }
        }
        .ant-steps-item-icon {
            @include stepDot();
        }
        .ant-steps-item-container .ant-steps-item-content > .ant-steps-item-title {
            color: $progressbar-green;
            @include font($font-xsmall-size, $font-muli-semibold);
            &::after {
                background-color: $spring-green;
            }
        }
    }

    .ant-steps-item-wait {
        .ant-steps-item-icon {
            @include stepDot();
            box-shadow: none;
            background-color: $progressbar-grey;
            .ant-steps-icon {
                color: transparent;
                &:hover {
                    color: transparent !important;
                }
            }
        }
    }

    .ant-steps-item[role='button']:not(.ant-steps-item-process):hover {
        .ant-steps-item-icon .ant-steps-icon {
            color: transparent !important;
        }
    }

    .ant-steps .ant-steps-item-process:first-child {
        .ant-steps-item-title::before {
            display: none;
        }
    }

    .ant-steps-horizontal {
        .ant-steps-item-finish {
            .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
                &::before {
                    background-color: $spring-green;    
                }
                &::after {
                    background-color: $spring-green;
                }
            }
            &:first-child {
                .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
                    &::before {
                        display: none;
                    }
                }
            }
            .ant-steps-item-icon {
                @include stepDot();
            }
        }
        &:not(.ant-steps-label-vertical) .ant-steps-item {
            margin-right: 0;
        }
    }
}

@media screen and (max-width: $breakpoint-smphone) {
    .ant-steps-horizontal.ant-steps-label-horizontal {
        .ant-steps-item-icon {
            float: none;
            margin-right: 0;
        }
        .ant-steps-item-content {
            min-height: 0;
        }
    }
}

@media screen and (min-width: $breakpoint-mdmdesktop) {
    .step-wrapper {
        width: 60%;
        margin: 0 auto;
    }
}

@media screen and (max-width: $breakpoint-smphone) {
    .ant-steps-item {
        width: 100%;
    }
    .payment-info-form {
        width: 100%;
        text-align: center;
    }
}
