.cs-table-wrapper {
    .select-all-items-pages {
        font-size: 15px;
        font-weight: bold;
        display: block;
        margin-bottom: 15px;
        .select-all-items {
            text-decoration: underline;
            cursor: pointer;
            color: #1a73e8;
            margin-left: 5px;
        }
        .select-all-custom-message {
            width: 100%;
            display: block;
            margin-top: 15px;
            color: #666;
            font-size: 12px;
        }
    }
    .filter-sort-list {
        margin: 0;
        label {
            @include font($font-small-size, $font-muli-regular);
            color: $black;
        }
        .ant-radio-wrapper {
            width: 100%;
            padding: 4px;
            border: none;
            &.ant-radio-wrapper-checked {
                background-color: $bg-txt-highlight;
            }
        }
        .anticon {
            font-size: 13px;
            color: $black;
            margin-left: 5px;
        }
        .sub-filter {
            padding: 6px 0;
            border-radius: 10px;
            margin-top: 12px;
            label {
                color: $black;
                font-family: $font-muli-bold;
            }
        }
        .two-sided-wrapper {
            display: inline-block;
            width: 100%;
            text-align: left;
            @media screen and (min-width: 481px) {
                grid-template-columns: repeat(2, minmax(70px, 1fr)) !important;
            }
            .two-side-part {
                width: 48%;
                border: 1px solid $login-linebreak-grey;
                border-radius: 10px;
                margin: 5px 0;
                padding: 5px;
                float: left;
                &.full-wrapper {
                    width: 100%;
                    @media screen and (min-width: 600px) and (max-width: $breakpoint-mdtablet) {
                        width: 48%;
                    }
                }
                &:last-child {
                    float: right;
                }
                &:nth-child(2) {
                    @media screen and (max-width: $breakpoint-smphone) {
                        margin-left: 0;
                    }
                }
                @media screen and (max-width: $breakpoint-smphone) {
                    width: 100%;
                }
                @media screen and (max-width: $breakpoint-mdtablet) {
                    .half-wrapper {
                        width: 100%;
                    }
                }
                @media screen and (max-width: $breakpoint-lgphone) {
                    .ant-radio-group {
                        width: 100%;
                    }
                }
            }
            .sub-filter label {
                padding: 5px 7px;
            }
        }
        @media screen and (min-width: $breakpoint-mdtablet) {
            display: none;
        }
        &.table-1200 {
            @media screen and (min-width: $breakpoint-large) {
                display: none;
            }
            @media screen and (max-width: $breakpoint-large) {
                display: block;
            }
            .two-sided-wrapper {
                .two-side-part {
                    @media screen and (max-width: $breakpoint-large) {
                        .half-wrapper {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .main-title {
            & > .ant-collapse-header {
                font-family: $font-muli-bold !important;
                border-bottom: none;
                color: $txt-table-header;
            }
        }
        .ant-collapse > .ant-collapse-item {
            border-bottom: none;
            .ant-collapse-header {
                @include font($font-small-size, $font-muli-regular);
                padding: 4px 8px;
                background-color: transparent;
                i {
                    padding: 3px;
                }
            }
        }
        .ant-collapse-content-box {
            width: 100%;
            padding: 0;
        }
    }
    .custom-scrollble-table {
        width: 100%;
        text-align: left;
        margin-top: 8px;
        float: left;
        min-height: 550px;
        .ant-pagination {
            display: inherit;
            text-align: right;
            margin-bottom: 26px;
            .ant-pagination-item-active {
                border-color: transparent;
                a {
                    color: $pale-green;
                    font-family: $font-muli-bold;
                }
            }
            li {
                padding: 10px 5px 5px 5px;
                &:before {
                    display: none;
                }
            }
        }
        @media screen and (max-width: $breakpoint-mdtablet) {
            min-height: auto;
        }
        table {
            @media screen and (max-width: $breakpoint-mdtablet) {
                display: none;
            }
            &.no-mobile-view {
                display: block;
            }
            &.table-1200 {
                @media screen and (max-width: $breakpoint-large) {
                    display: none;
                }
                @media screen and (min-width: $breakpoint-large) {
                    display: block;
                }
            }
        }

        tr {
            th {
                border-bottom: transparent;
                padding: 12px 8px 12px 8px;
                @include font($font-xsmall-size, $font-muli-semibold);
                text-transform: uppercase;
                color: $txt-table-header;
                text-align: left;
                background: rgba(225, 225, 225, 0.3);
                -webkit-transition: background 0.3s ease;
                transition: background 0.3s ease;
                min-width: 150px;
                word-wrap: break-word;
                word-break: break-word;
            }
            td {
                color: $txt-table-content;
                border-bottom: 1px solid $plain-grey;
                padding: 12px 8px;
                line-height: 1.3;
                overflow: hidden;
                &:last-child {
                    overflow: inherit;
                }
                text-overflow: ellipsis;
                word-wrap: break-word;
                word-break: break-word;
            }
        }
        thead,
        tbody {
            tr {
                display: table;
                width: 100%;
                table-layout: fixed;
                /* even columns width , fix width of table too*/
                min-width: 171px;
            }
            th.row-check,
            td.row-check {
                width: 50px !important;
                min-width: 50px !important;
            }
        }
        tbody {
            display: block;
            height: auto;
            // min-height: 120px;
            // overflow: auto;
        }
        thead {
            width: calc(100% - 1em); /* scrollbar is average 1em/16px width, remove it from thead width */
        }
    }
    .dropdown-active {
        background-color: $bg-lighter-highlight;
        border-radius: 10px;
    }
}

.no-min-height-table {
    .custom-scrollble-table {
        min-height: auto;
    }
}

.table-header {
    &-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &-sorter-wrapper {
        flex: 4;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    &-title {
        margin-right: 5px;
        line-height: 1.4;
    }
}

.pointer {
    cursor: pointer;
}

.custom-sort {
    &-active {
        color: $black !important;
    }
    &-inactive {
        color: rgb(191, 191, 191) !important;
    }
}

.empty-table-msg {
    @include font($font-small-size, $font-muli-bold);
    text-align: center;
    color: $black;
}

.table-pagination {
    float: right;
}

.ant-tabs-content .cs-table-wrapper {
    padding: 0 32px;
}

/**Responsiveness */
@media screen and (max-width: $breakpoint-mddesktop) {
    .custom-scrollble-table {
        thead,
        tbody {
            tr {
                table-layout: auto;
            }
        }
    }
}
