@font-face {
    font-family: $font-muli-light;
    font-style: normal;
    font-weight: $font-weight-light;
    src: url('../../assets/font/Muli/Muli-Light.ttf');
}

@font-face {
    font-family: $font-muli-regular;
    font-style: normal;
    font-weight: $font-weight-light;
    src: url('../../assets/font/Muli/Muli-Regular.ttf');
}

@font-face {
    font-family: $font-muli-semibold;
    font-style: normal;
    font-weight: $font-weight-semibold;
    src: url('../../assets/font/Muli/Muli-SemiBold.ttf');
}

@font-face {
    font-family: $font-muli-bold;
    font-style: normal;
    font-weight: $font-weight-bold;
    src: url('../../assets/font/Muli/Muli-Bold.ttf');
}

@font-face {
    font-family: $font-muli-extrabold;
    font-style: normal;
    font-weight: $font-weight-extrabold;
    src: url('../../assets/font/Muli/Muli-ExtraBold.ttf');
}

@font-face {
    font-family: $font-didact-regular;
    font-style: normal;
    font-weight: $font-weight-light;
    src: url('../../assets/font/Didact_Gothic/DidactGothic-Regular.ttf');
}

@font-face {
    font-family: $font-comfortaa-bold;
    font-style: normal;
    font-weight: $font-weight-bold;
    src: url('../../assets/font/Comfortaa/static/Comfortaa-Bold.ttf');
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/**Link */
body .btn-link,
body a {
    cursor: pointer;
    color: $pale-green;
    &:hover,
    &:focus {
        color: $pale-green;
    }
}

/**Input field */
input {
    font-family: $font-muli-regular;
}

/**Wrappers */
html {
    .address {
        white-space: pre-wrap;
    }
    .ant-select-dropdown {
        z-index: 99999;
    }
    .m-0 {
        margin: 0 !important;
    }
    .p-0 {
        padding: 0 !important;
    }
    .no-single-selection-margin {
        .ant-select-selection--single {
            margin-top: 0;
            margin-left: 0;
        }
    }
    .feature-available-for-manual {
        font-size: 18px;
        font-weight: bold;
        color: #000;
    }
    .zendesk-reminder-default-title {
        margin-bottom: 30px;
        color: #000;
        text-align: left;
        font-weight: bold;
    }
    .zendesk-event-type-settings-form-section {
        p {
            margin-top: 0;
            font-size: 14px;
        }
    }
    .item-has-add-on-block {
        min-width: 400px;
        @media screen and (max-width: 460px) {
            min-width: 100%;
        }
        .ant-input-group-addon {
            .formfield-select {
                width: 290px;
                @media screen and (max-width: 460px) {
                    width: auto;
                }
            }
        }
    }
    .multiselect-field .ant-select-selection--multiple {
        height: auto;
        padding: 4px;
        li {
            &.ant-select-search {
                padding: 0;
            }
        }
        .ant-select-selection__choice {
            height: auto;
            padding: 1px 20px 0 10px;
        }
    }
    .form-field-view-only,
    .form-field-view-only:active,
    .form-field-view-only:hover,
    .form-field-view-only:focus,
    .form-field-view-only input[disabled],
    .form-field-view-only input[disabled]:active,
    .form-field-view-only input[disabled]:hover,
    .form-field-view-only input[disabled]:focus,
    .form-field-view-only .ant-select-selection {
        border: none !important;
        background: #fff !important;
        color: #000 !important;
        font-weight: bold;
        box-shadow: none;
        resize: none;
        .anticon {
            display: none;
        }
        .ant-arrow {
            display: none;
        }
    }

    .custom-pp-modal {
        width: 700px !important;
        max-width: 90%;
    }

    .half-wrapper {
        width: 45%;
        float: left;
    }
    .full-wrapper {
        width: 100%;
        float: left;
    }
    .full-width-title {
        width: 100% !important;
    }
    .right-align {
        float: right;
    }
    #root .mid-wrapper {
        width: 70%;
        margin: 0 auto;
    }
    .notification-img-wrapper {
        max-width: 45px;
        min-width: 25px;
        border-radius: 100%;
        float: left;
        img {
            width: 100%;
            height: auto;
            border-radius: 100%;
        }
    }
    a.accounting-software-external-link {
        padding-bottom: 16px;
        display: block;
        text-decoration: underline;
    }
    .payment-page {
        a.accounting-software-external-link {
            padding-bottom: 0;
        }
    }
    .demo-company-modal-ok-hidden {
        display: none;
    }
    .demo-company-please-wait-modal .ant-modal-confirm-content {
        font-weight: bold;
        font-size: 18px !important;
        color: #666 !important;
    }
    .demo-company-confirm-before-try {
        .ant-modal-confirm-btns {
            float: none;
            text-align: center;
        }
    }
    .box-wrapper .right-align {
        @include font($font-small-size, $font-muli-regular);
        text-align: right;
        width: 29%;
        h5 {
            text-align: left;
            margin-bottom: 7px;
        }
    }
    .three-wrapper {
        width: 30%;
        display: inline-block;
    }
    img.is-default-image-45x45-loaded {
        width: 45px !important;
        height: 45px !important;
    }

    img.is-default-image-77x77-loaded {
        width: 77px !important;
        height: 77px !important;
    }

    img.is-default-image-210x210-loaded {
        width: auto !important;
        max-width: 210px !important;
        max-height: 150px !important;
    }

    img.is-default-image-254x254-loaded {
        width: 254px !important;
        height: 254px !important;
    }

    img.logo-210 {
        width: auto !important;
        max-width: 210px !important;
        max-height: 150px !important;
    }
    .ant-tooltip {
        z-index: 99999999;
    }
    .ant-tooltip-inner {
        background: #000000;
    }
    .word-wrap {
        word-wrap: break-word;
    }
    .custom-content-details-wrapper {
        padding: 10px 0;
        word-wrap: break-word;
        word-break: break-word;
        text-align: left;
        .basic-list {
            li {
                &::before {
                    display: none;
                }
            }
            a {
                text-align: left;
            }
        }
        #paycepaid-table {
            p {
                margin-top: 20px;
            }
        }
    }

    .ticket-details-modal {
        width: 1000px !important;
        max-width: 95%;
    }

    .d-block {
        display: block;
    }

    body #root .tab-wrapper {
        padding: 0;
        .ant-tabs-bar {
            padding: 16px 39px 0 39px;
            @media screen and (max-width: $breakpoint-mdphone) {
                padding: 0 5px 0 5px;
            }
        }
        .ant-table-wrapper,
        .home-content-wrapper {
            padding: 0 35px;
            @media screen and (max-width: $breakpoint-lgphone) {
                padding: 0 20px;
            }
        }
        .ant-table-wrapper {
            @media screen and (max-width: $breakpoint-mdphone) {
                padding: 0 10px;
            }
        }
    }
}

/**App */
body {
    font-family: $font-muli-regular, $font-fallback;
    margin: 0;
    padding: 0;
    #root,
    .App {
        height: 100%;
    }
    /**Antd styles inside body wrapped*/
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        box-shadow: 0 0 0 1000px $white inset;
        -webkit-box-shadow: 0 0 0 1000px $white inset;
    }
    /**Header */
    header {
        .common-header-wrapper {
            max-width: 1440px;
            margin: 0 auto;
        }
        h1 {
            margin-top: -7px;
        }
        .logo {
            @include size(180px, 35px);
            background: url('/images/paycepaid-logo.png') no-repeat;
            margin: 16px 0;
            float: left;
            display: block;
            background-size: contain;
        }
    }
    .header-wrapper {
        @include size(100%, 80px);
        max-width: 1130px;
        margin: 0 auto;
    }
    /**Footer */
    footer {
        text-align: center;
        width: 100%;
    }
    .required-star {
        color: red;
    }
    .ant-calendar-picker-container {
        z-index: 99999;
    }
    /**Wrappers */
    .info-form-wrapper {
        width: 80%;
        min-height: 772px;
        float: none;
        margin: 0 auto;
        padding: 30px 58px;
        overflow: auto;
        .upload-btn {
            @include size(95px, 43px);
            @include buttonSpec($spring-green, $white, $spring-green);
            @include borderRadius(10px);
            float: left;
            margin-left: 25px;
            padding: 11px;
            font-family: $font-muli-bold, $font-fallback;
            &:hover {
                @include buttonSpec($mild-spring-green, $mild-spring-green, $white);
                cursor: pointer;
                font-family: $font-muli-semibold, $font-fallback;
            }
        }
    }
    .checkbox-wrapper {
        span {
            font-size: $font-smallToMed-size;
        }
        .checkbox-sub-desc {
            margin-left: 21px;
            color: $txt-sub-content;
        }
    }
    .information-wrapper {
        .inner-desc {
            color: $black;
            font-family: $font-muli-light;
            margin-bottom: 45px;
        }
        .info-form-wrapper {
            margin-bottom: 40px;
        }
        .ant-form-vertical .ant-form-item {
            padding-bottom: 0;
        }
        .button-wrapper {
            width: 60%;
            margin: 0 auto;
        }
    }
    b {
        font-family: $font-muli-extrabold;
    }
    .white-box-wrapper {
        @include borderRadius(10px);
        box-shadow: 0 8px 6px 0 rgba(215, 241, 210, 0.38), 0 0 6px 0 rgba(0, 0, 0, 0.12);
        background-color: $white;
        padding: 34px 46px;
        margin: 30px 0;
        width: 49%;
        @media screen and (max-width: $breakpoint-lgtablet) {
            width: 96%;
        }
        ul {
            display: inline-grid;
            grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
            width: 100%;
            li {
                padding: 10px 13px;
                text-align: center;
                word-wrap: break-word;
                b {
                    @include font(20px, $font-muli-semibold);
                    color: $black;
                    word-break: keep-all;
                }
                .count-info {
                    @include font(10px, $font-muli-semibold);
                    color: $txt-count-info;
                    margin-top: 2px;
                    text-transform: uppercase;
                }
                &::before {
                    @include size(2px, 21px);
                    content: '';
                    color: $menu-break;
                    float: right;
                    margin-right: -23px;
                    background-color: $menu-break;
                    margin-top: 4px;
                }
                &:last-child::before {
                    display: none;
                }
            }
        }
        &.full-wrapper {
            width: 92%;
            padding: 10px 30px;
            @media screen and (max-width: $breakpoint-lgtablet) {
                width: 96%;
            }
            margin: 0 auto;
            float: none;
            overflow: auto;
            margin-bottom: 30px;
        }
        &.default-white-box-padding {
            padding: 34px 46px;
        }
        .select-tags-mode {
            &.zendesk-tags {
                ul {
                    display: block;
                    li {
                        padding: 0 20px 0 10px;
                    }
                }
            }
        }
    }
    .count-wrapper {
        margin: 0 40px;
        overflow: auto;
        padding: 0 11px;
        @media screen and (max-width: $breakpoint-lgtablet) {
            width: 96%;
            margin: 0 auto;
            padding: 0 5px;
        }
        .white-box-wrapper {
            padding: 15px;
            margin-top: 0;
            width: 49%;
        }
    }
    .box-wrapper {
        .page-title {
            font-size: $font-mediumToLg-size;
            margin: 0;
            text-align: left;
            width: 45%;
            display: inline-block;
            margin-bottom: 20px;
        }
    }
    .addorg-connect {
        width: 600px !important;
        max-width: 90%;
        .ant-modal-confirm-btns {
            float: none;
            text-align: center;
        }
    }
    .table-button-wrapper {
        button {
            @include size(35px, 35px);
            @include borderRadius(25px);
            border: transparent;
            padding: 8px;
            margin-right: 15px;
            margin-bottom: 10px;
            outline: none;
            &.email,
            &.sms,
            &.whatsapp,
            &.ivr {
                width: 110px !important;
            }
            &.email {
                background: url('../../assets/images/email.png') no-repeat center;
                background-color: $bg-danger;
                color: #e8627c;
                text-indent: 10px;
            }
            &.sms {
                background: url('../../assets/images/sms.png') no-repeat center;
                background-color: $bg-warning;
                color: #af8e0b;
                text-indent: 10px;
            }
            &.ivr {
                background: url('../../assets/images/call.png') no-repeat center;
                background-color: $bg-ready;
                color: #29b140;
                text-indent: 10px;
            }
            &.whatsapp {
                background: url('../../assets/images/whatsapp.png') no-repeat center;
                background-color: $bg-ready;
                color: #25d366;
                text-indent: 10px;
            }
            &.high-priority {
                background-color: $bg-danger;
                color: #e8627c;
                height: auto;
                width: auto;
                padding: 10px;
            }
            &.medium-priority {
                background-color: $bg-warning;
                color: #af8e0b;
                height: auto;
                width: auto;
                padding: 10px;
            }
            &.low-priority {
                background-color: $bg-ready;
                color: #29b140;
                height: auto;
                width: auto;
                padding: 10px;
            }
        }
    }
    /**signin form */
    .signin-form {
        overflow: hidden;
        padding: 0 20px;
        text-align: left;
        .hide-login-form {
            display: none;
        }
        .ant-form-horizontal {
            .ant-form-item {
                padding-bottom: 0;
                margin-bottom: 17px;
            }
            .signin-password-wrapper .ant-form-item {
                margin-bottom: 0;
            }
            .forgot-pwd-link {
                @include font($font-small-size, $font-muli-semibold);
                color: $mild-spring-green;
                cursor: pointer;
                padding-left: 0;
            }
        }
    }
    .signin-btn-wrapper {
        text-align: center;
        margin-bottom: 0;
        width: 100%;
        float: left;
        button {
            margin-top: 22px;
        }
    }
    .close-icn {
        @include font($font-small-size, $font-muli-bold);
        @include size(20px, 20px);
        @include borderRadius(25px);
        background-color: $bg-danger;
        color: $bg-critical-danger;
        text-align: center;
        cursor: pointer;
    }
    .multiple-select-block {
        .ant-select-selection {
            height: auto;
        }
    }
    /**Custom popover */
    .organisation-popover,
    .reminder-popover {
        padding: 0 2px;
        .org-details {
            @include font($font-smallToMed-size, $font-muli-bold);
            // margin-bottom: 22px;
            // margin-top: 22px;
            .ant-avatar {
                margin-right: 8px;
                margin-left: 20px;
            }
            .org-name {
                margin-right: 20px;
                margin-bottom: 30px;
            }
        }
        li.ant-menu-item {
            padding-left: 30px;
            padding-right: 30px;
            margin-bottom: 0 !important;
            margin-top: 0;
            &:not(:last-child) {
                border-bottom: 1px solid #e8e8e8;
            }
            a:hover {
                color: rgba(0, 0, 0, 0.65);
            }
            &:hover {
                background: $bg-lighter-highlight;
            }
            .anticon {
                padding-left: 0 !important;
            }
        }
    }
    .main-menu-popover {
        .popover-item {
            color: $menu;
            padding: 10px 0;
        }
        .main-menu-item {
            padding: 0 30px;
            &:hover {
                background-color: $bg-lighter-highlight;
                cursor: pointer;
            }
        }
    }
    /**Links */
    .link {
        @include font($font-small-size, $font-muli-semibold);
        color: $pale-green;
        width: 100%;
        float: left;
        margin-top: 6px;
        margin-bottom: 22px;
        cursor: pointer;
    }
    .skip-now-link {
        .link {
            @include font($font-smallToMed-size, $font-muli-semibold);
            width: 100%;
            float: left;
        }
    }
    .crud-link {
        cursor: pointer;
    }
    .crud-info-wrapper {
        font-size: 10px;
        color: #666;
    }
    .crud-wrapper {
        width: 100%;
        display: inline-block;
        line-height: 1.5;
        margin-top: 4px;
        padding-right: 0;
        .crud-link {
            margin-right: 8px;
            margin-left: 8px;
            color: $menu;
            font-size: $font-xsmall-size;
            cursor: pointer;
            &:hover {
                color: $pale-green;
                text-decoration: underline;
            }
            &:after {
                @include size(1px, 14px);
                content: '';
                color: $menu;
                float: right;
                margin: 0 8px;
                background-color: $txt-detail;
                margin-top: 4px;
                position: absolute;
            }
            &:first-child {
                margin-left: 0;
            }
        }
        span .crud-link:after {
            display: inline-block !important;
        }
        .crud-link:last-child:after {
            display: none;
        }
        span {
            &:not(:first-child) {
                margin-left: 5px;
            }
            &:last-child .crud-link:last-child:after {
                display: none !important;
            }
        }
    }
    /**Table */
    table {
        @include font($font-small-size, $font-muli-regular);
        color: $txt-table-content;
        td {
            vertical-align: middle;
        }
    }
    /* Scrollbar width */
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Scrollbar Track */
    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    /* Scrollbar Handle */
    ::-webkit-scrollbar-thumb {
        background: $progressbar-grey;
        border-radius: 10px;
    }

    /* Scrollbar Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: $light-grey;
    }
    /**Common */
    .hide-comp {
        display: none;
    }
    .hide-range-filter {
        display: none !important;
    }
    .email {
        background-color: $bg-danger;
    }
    .sms {
        background-color: $bg-warning;
    }
    .ivr {
        background-color: $bg-ready;
    }
    .center-align {
        text-align: center !important;
        .ant-col {
            text-align: center;
        }
    }
    .no-text-indent {
        text-indent: 0;
    }
    .full {
        width: 100%;
    }
    .left-align {
        float: left;
    }
    .align-right {
        float: right;
    }
    .no-margin {
        margin: 0;
    }
    .red-icon-text {
        color: $red !important;
    }
    .right-align-text {
        text-align: right;
    }
    #root .left-align-text {
        text-align: left;
    }
    .unsynced-icon {
        font-size: 23px;
        color: $red;
    }
    .custom-filter {
        cursor: pointer;
        margin-left: 15px;
        color: rgba(0, 0, 0, 0.5);
        padding: 4px 0;
        &:hover {
            color: rgba(0, 0, 0, 1);
        }
    }
    .custom-drop-down {
        position: absolute;
        width: 85% !important;
        min-width: 180px;
        top: 35px;
        left: 4px;
        background: $white;
        border-radius: 4px;
        -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        margin-top: 5px;
        display: inline-block !important;
        z-index: 9999;
        .custom-dd-list {
            text-align: left !important;
            padding: 0;
            div {
                label {
                    span {
                        white-space: normal;
                        display: inline-flex;
                    }
                }
            }
            label {
                text-transform: capitalize;
            }
            button {
                @include font($font-small-size, $font-muli-regular);
                padding: 0;
            }
            &.last-confirm-row {
                border-top: 1px solid $dim-grey;
                padding: 0 12px;
            }
            &:before {
                display: none;
            }
        }
        .ant-radio-wrapper {
            padding: 5px 13px;
            width: 100%;
            &.ant-radio-wrapper-checked {
                border: none;
                background-color: $bg-txt-highlight;
            }
        }
    }
    .body-overflow-scroll {
        max-height: 180px;
    }
    .synced-icon {
        font-size: 23px;
        color: $pale-green;
    }
    .breadcrumb-spec {
        margin-bottom: 30px !important;
    }
    .lock-icon {
        font-size: 60px;
    }
    #root .mrgn-tp-25 {
        margin-top: 25px;
    }
    .mrgn-left-15 {
        margin-left: 15px;
    }
    .tooltip-color {
        color: rgba(0, 0, 0, 0.45);
    }
    .fnt-sz-25 {
        font-size: 25px;
    }
    .fnt-sz-50 {
        font-size: 50px;
    }
    .email-body {
        z-index: 1;
        margin-top: -21px;
    }
    .selected-plan-details {
        font-size: 15px;
        padding-left: 10px;
        margin-top: -15px;
    }
    .warning-text {
        font-size: 16px;
    }
    .react-crop {
        max-width: 500px;
    }
    .initial-pos {
        position: initial;
    }
    .rel-pos {
        position: relative;
    }
    .last-synced {
        pointer-events: none;
        color: rgba(0, 0, 0, 0.5);
    }
    .sub-menu-custom {
        &.show-element {
            display: block;
        }
        &.hide-element {
            display: none;
        }
    }
    .settings-tab-arrow {
        margin: 0;
        margin-bottom: 25px;
    }
    .table-warning-icon {
        color: $bg-alert;
        font-size: 18px;
    }
    .no-border {
        overflow: hidden;
        border: 0;
        border-bottom: initial !important;
    }
    .settings-body {
        .right-align {
            margin-top: -19px;
            margin-bottom: 6px;
            z-index: 99999;
        }
    }
    td.image-badge {
        width: 80px !important;
    }
    .align-center {
        float: none;
        margin: 0 auto;
        overflow: auto;
        margin-bottom: 15px;
    }
    .overview-card-loader img {
        width: 25px !important;
        height: 25px !important;
    }
    .alert-info {
        @include font($font-small-size, $font-muli-extrabold);
        @include borderRadius(10px);
        padding: 11px 38px;
        background-color: $bg-alert;
        color: $white;
    }
    .info {
        @include font($font-small-size, $font-muli-extrabold);
        background-color: $bg-txt-highlight;
        position: absolute;
        top: 34px;
        color: #2a9f3e;
        padding: 11px 58px;
        right: 0;
        min-height: 36px;
        text-transform: uppercase;
        &::before {
            @include size(0, 0);
            content: '';
            border-top: 19px solid transparent;
            border-bottom: 17px solid transparent;
            border-left: 28px solid $white;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    .logo-placeholder {
        padding: 5px;
    }
    .subscription-logo-placeholder {
        padding: 9px;
        padding-top: 15px;
    }
    .full-width {
        .form-control {
            width: 100%;
            input {
                padding: 0 8px;
            }
        }
        .has-error.has-feedback .ant-form-item-children-icon,
        .has-success.has-feedback .ant-form-item-children-icon {
            margin-top: 11px;
        }
    }
    .no-data-text {
        font-weight: 400;
        color: $black;
        padding: 5px 19px;
    }
    .table-name-align {
        display: inline-grid;
        grid-template-columns: 54px 2fr;
        align-items: center;
    }
    #root .form-mini-title {
        @include font(10px, $font-muli-semibold);
        border-radius: 100px;
        background-color: #f5f5f9;
        color: $black;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        padding: 8px;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        margin-bottom: 26px;
        margin-top: 30px;
        min-width: 122px;
    }
    .popover-action-btn {
        @include font($font-small-size, $font-muli-semibold);
    }
    .import-error-wrapper {
        text-align: left;
        font-size: $font-small-size;
        padding: 8px;
        margin-bottom: 15px;
        overflow: hidden;
        border: 2px dashed $progressbar-grey;
        background-color: $bg-warning;
        header {
            margin: 5px;
            margin-left: 0;
        }
        i {
            font-size: 30px;
            color: $dark-red;
            float: left;
        }
        .error-details {
            width: 95%;
            float: left;
            margin-left: 8px;
        }
        .single-warning {
            @include font($font-smallToMed-size, $font-muli-semibold);
            margin-top: 8px;
            margin-left: 44px;
        }
        ul {
            display: block;
            li {
                padding: 5px;
                padding-left: 0;
                padding-bottom: 0;
                text-align: left;
                position: relative;
                text-indent: 14px;
                &:before {
                    @include size(5px, 5px);
                    content: '';
                    border-radius: 5px;
                    background-color: $menu;
                    position: absolute;
                    top: 6px;
                    left: 0;
                }
                &:last-child {
                    &:before {
                        display: block;
                    }
                }
                ul {
                    margin-left: 15px;
                }
            }
        }
    }
    .cke_contents {
        height: 350px !important;
    }
    .register-title {
        @include font($font-medium-size, $font-muli-bold);
        margin-bottom: 21px;
    }
    .select-field-short {
        width: 135px;
    }
    .my-account-business-info {
        margin-left: 23px;
    }
    .my-profile-detail {
        .profile-spec-mid-wrapper {
            width: 88%;
        }
    }
    .counter {
        @include font($font-xsmall-size, $font-muli-extrabold);
        @include size(19px, 18px);
        float: right;
        background-color: $red;
        border-radius: 50%;
        color: $white;
        text-align: center;
    }
    .table-button-wrapper.tag-linker {
        width: 45%;
        display: inline-block;
        button {
            font-size: $font-small-size;
            @include size(101px, 35px);
            @include borderRadius(10px);
            background-position: 7%;
            margin-bottom: 0;
        }
    }
    .summary-plan-details {
        .plan-days {
            @include font($font-small-size, $font-muli-regular);
            color: $black;
        }
        summary {
            @include font($font-small-size, $font-muli-light);
            color: $txt-communication-detail;
            margin-top: 3px;
        }
    }
    .active-icon {
        padding: 10px;
        display: none;
        &.menu-item.notifications-highlight {
            margin-left: 4px;
        }
        &.menu-item.contacts-highlight {
            margin-left: 2px;
            margin-top: 8px;
        }
        &.menu-item.invoices-highlight {
            margin-left: 2px;
            margin-top: 4px;
        }
        &.menu-item.dashboard-highlight {
            margin-left: 2px;
        }
        &.menu-item.reminders-highlight {
            margin-left: 1px;
        }
        &.menu-item.organisations-highlight {
            margin-left: 2px;
            padding: 6px;
        }
    }
    .mob-only {
        display: none;
    }
    img.view-icon {
        @include size(20px, 20px);
    }
    .readonly-field {
        pointer-events: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .menu-item-bg {
        .menu-border {
            @include size(53px, 53px);
            @include borderRadius(25px);
            border: 2px solid $white;
            padding: 9px;
        }
    }
    .canvasjs-chart-credit {
        display: none;
    }
    .success-icon {
        height: 85px;
    }
    hr {
        height: 1px;
        border: 1px solid $menu-break;
        margin-bottom: 18px;
    }
    .has-error.has-feedback .react-phone-number-input,
    .has-error.has-feedback .PhoneInput {
        border: 1px solid $red;
    }
    .phone-field-wrapper .react-phone-number-input__icon,
    .phone-field-wrapper .PhoneInputCountryIcon {
        padding-top: 0 !important;
        box-shadow: none;
        background: none;
    }
    .connection-success {
        font-weight: bold;
        color: #6ec45e;
        margin-top: 5px;
    }
    .connection-failed {
        font-weight: bold;
        color: #ff0000;
        margin-top: 5px;
    }
    .not-connected {
        font-weight: bold;
        color: #1a73e8;
        margin-top: 5px;
    }
    .text-left {
        text-align: left;
    }
    .unread-notification td {
        cursor: pointer;
    }
    #root .red-text {
        color: $dark-red;
    }
    #root .green-text {
        color: $spring-green;
    }
    /** Custom Modal */
    .placeholder-wrapper {
        position: relative;
        float: right;
        text-align: right;
        .blue-bg-button {
            margin-bottom: 2px;
        }
        .placeholder-list {
            @include borderRadius(8px);
            width: 230px;
            position: absolute;
            z-index: 9999;
            max-height: 230px;
            overflow-y: scroll;
            overflow-x: hidden;
            background-color: $white;
            box-shadow: 0 -4px 23px 0 rgba(215, 241, 210, 0.88), 0 0 6px 0 rgba(0, 0, 0, 0.52);
            border: 2px solid $menu-break;
            grid-template-columns: repeat(1, minmax(90px, 1fr)) !important;
            left: -45px;
            ul {
                display: block;
                li {
                    text-align: left !important;
                    cursor: pointer;
                    padding: 0;
                    .placeholder-key {
                        padding: 3px 12px;
                        background-color: $txt-line-break;
                        color: $white;
                        cursor: text;
                    }
                    .placeholder {
                        padding: 3px 12px;
                        &:hover {
                            background-color: rgba(0, 0, 0, 0.08);
                        }
                    }
                }
            }
        }
    }
    .textarea-counter {
        i {
            color: $pale-green;
            margin-right: 10px;
            font-weight: bold;
        }
    }
    .delete-confirm-popup {
        .ant-popover-inner-content {
            padding: 15px;
            .ant-btn-sm {
                @include size(65px, 28px);
                font-size: 14px;
            }
        }
    }
    .custom-form-modal {
        text-align: center;
        header,
        h1 {
            @include font(32px, $font-muli-bold);
            justify-content: center; //for display flex
            color: $black;
            text-transform: capitalize;
        }
        .ant-upload-drag {
            width: 50%;
            margin: 0 auto;
            margin-top: 25px;
        }
        &.text-left .edit-payment-form {
            text-align: left;
            overflow: auto;
            padding: 0 20px;
            margin-top: 25px;
            .ant-col {
                @media screen and (max-width: $breakpoint-smphone) {
                    width: 100%;
                }
            }
        }
        .ant-modal-close {
            box-shadow: initial;
            &:hover {
                background-color: transparent;
                transform: none;
            }
        }
    }
    .custom-modal {
        color: $black;
        .ant-modal-body {
            header {
                @include font(35px, $font-muli-semibold);
                width: 65%;
                margin: 0 auto;
                line-height: 1.13;
                text-transform: capitalize;
                text-align: center;
            }
            .desc-text {
                @include font($font-medium-size, $font-muli-regular);
                text-align: center;
                width: 80%;
                margin: 0 auto;
                margin-top: 26px;
            }
            .forgot-pwd-modal-form {
                margin-top: 35px;
                text-align: center;
                .modal-field-wrapper {
                    text-align: left;
                }
                label {
                    @include font($font-small-size, $font-muli-regular);
                    text-transform: capitalize;
                    margin-bottom: 10px;
                }
                .modal-link-wrapper {
                    margin-top: 15px;
                }
                .modal-link {
                    @include font($font-smallToMed-size, $font-muli-semibold);
                }
                button.ant-btn.big-btn {
                    padding: 8px;
                }
            }
        }
        .ant-modal-footer {
            border-top: transparent;
            display: none;
        }
    }
    .error {
        color: $red;
        @include font($font-small-size, $font-muli-regular);
    }
}

.block-ui-container {
    cursor: not-allowed;
}

.invoice-paid-on-datepicker {
    z-index: 9999;
}

/**Responsive */
@media screen and (max-width: 399px) {
    .myaccount-content-wrap {
        .edit-button {
            float: left;
            margin-top: 5px;
            margin-left: 15px;
        }
    }
}

@media (max-width: $breakpoint-smphone) {
    body #root {
        .white-box-wrapper {
            padding: 34px 8px;
        }
        .signin-btn-wrapper {
            margin-top: 26px;
        }
    }
}

@media screen and (min-width: $breakpoint-xlarge) {
    .my-account-profile .edit-add-profile-pic {
        bottom: -16px;
    }
    .my-account-business-info {
        margin-left: 23px;
        li:first-child {
            padding-left: 13px !important;
        }
    }
}

@media screen and (max-width: $breakpoint-lgtablet) {
    body #root {
        .white-box-wrapper {
            &.mid-wrapper {
                width: 96%;
            }
        }
    }
}

@media screen and (min-width: 1160px) {
    .info-form-wrapper {
        padding: 30px 113px;
    }
}

@media screen and (max-width: $breakpoint-mddesktop) {
    body {
        .dashboard-wrapper .count-wrapper {
            ul {
                grid-template-columns: repeat(3, minmax(90px, 1fr));
            }
        }
        .info-form-wrapper {
            .upload-btn {
                margin-top: 15px;
                margin-left: 0;
            }
        }
        .three-wrapper {
            width: 50%;
        }
    }
}

@media screen and (max-width: $breakpoint-mdphone) {
    body {
        .dashboard-wrapper .count-wrapper {
            ul {
                grid-template-columns: repeat(2, minmax(90px, 1fr));
            }
        }
        .info-form-wrapper {
            padding: 30px 0;
            width: 100%;
            box-shadow: none;
        }
        .three-wrapper {
            width: 100%;
        }
    }
}

@media screen and (min-width: 1024px) {
    body .custom-menu-selected {
        img.no-mob {
            display: none;
        }
        img.active-icon {
            display: block;
        }
    }
}

@media screen and (min-width: $breakpoint-tablet) {
    body #root .synced-name .details-list {
        h3.profile-name {
            text-align: left;
        }
        .sub-detail {
            text-align: left;
        }
    }
}

.ant-cascader-menu {
    height: auto !important;
}
