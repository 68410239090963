/**
* Variables
**/

/* Fonts Family*/
$font-muli-light: 'Muli Light';
$font-muli-regular: 'Muli Regular';
$font-muli-semibold: 'Muli SemiBold';
$font-muli-bold: 'Muli Bold';
$font-muli-extrabold: 'Muli ExtraBold';
$font-didact-regular: 'Didact Gothic';
$font-comfortaa-bold: 'Comfortaa Bold';
$font-fallback: sans-serif;

/**Font size */
$font-xsmall-size: 12px;
$font-small-size: 14px;
$font-smallToMed-size: 16px;
$font-medium-size: 18px;
$font-mediumToLg-size: 20px;
$font-mLarge-size: 28px;
$font-large-size: 30px;

/* Fonts Weights*/
$font-weight-light: 300;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;

/*Colors */
$white: #ffffff;
$black: #000000;
$red: #ff0000;
$dark-red: #e50000;
$pale-white: #e5e5ea;
$dim-white: #fafafa;
$pale-grey: #f9f9fb;
$plain-grey: #efeff4;
$light-grey: #787878;
$dim-grey: #e8e8e8;
$border-grey: #ececec;

$spring-green: #8ace7d;
$mild-spring-green: #72b266;
$pale-green: #6ec45e;
$progressbar-green: #65ca63;

/**Element Colors */
$progressbar-grey: #92959a;
$menu: #595964;
$login-linebreak-grey: #d9d9d9;
$menu-break: #f2f2f7;

/**Text Colors */
$txt-sub-content: #81818c;
$txt-line-break: #8e8e93;
$txt-detail: #afafb9;
$txt-communication-detail: #71717b;
$txt-table-header: #60606a;
$txt-table-content: #28282c;
$txt-tab-link: #313134;
$txt-count-info: #848494;
$txt-blue-btn-text: #007aff;

/**Background Colors */
$bg-arrow: #c7c7cc;
$bg-danger: #fff2f4;
$bg-warning: #fff9e1;
$bg-ready: #e6ffea;
$bg-alert: #f5c64a;
$bg-critical-danger: #ea7c76;
$bg-txt-highlight: #d7f1d2;
$bg-submenu: #7ec870;
$bg-txt-highlight: #d7f1d2;
$bg-lighter-highlight: #f3faf1;
$bg-page-title: #dff4da;
$bg-blue-btn: #e8f3ff;
$bg-blue-highlight: #d7e7f7;
$bg-menu-highlight: #f9f9f9;
$bg-admin: #f4f4fc;
$bg-notifications: #f2f2f2;

/* Media Query Breakpoint */
$breakpoint-smphone: 480px;
$breakpoint-phone: 576px;
$breakpoint-mdphone: 676px;
$breakpoint-lgphone: 767px;
$breakpoint-tablet: 768px;
$breakpoint-mdtablet: 867px;
$breakpoint-lgtablet: 991px;
$breakpoint-desktop: 992px;
$breakpoint-mddesktop: 1023px;
$breakpoint-mdmdesktop: 1024px;
$breakpoint-large: 1200px;
$breakpoint-xlarge: 1400px;
